import { merge } from "theme-ui";
import originalTheme from "@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index";

const theme = merge(originalTheme, {
  styles: {
    table: {
      // Unset default text alignment for table cells, so it can be defined in MDX
      [[`th`, `td`]]: {
          textAlign: undefined,
      },
    },
  },
});

export default theme;